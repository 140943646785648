.section-category {
	margin-top: 30px;

	.category-icon {
		display: table;
		width: 100%;
		min-height: 80px;

		> span {
			display: table-cell;
			vertical-align: middle;
			height: 60px;
		}
		img {
			width: 100px;
			margin: 0 auto;
			display: block;
		}
	}
}


.section-filter-category {
	// margin-top: -15px;

	.wrapper-filter {
		margin-top: 15px;
		position: relative;


		.is-dropdown-left,
		.is-dropdown-right {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: -35px;
				background-repeat: no-repeat;
				background-position: center center;
			}
			@include max-screen($mobile) {
				// width: 230px;
				width: 100%;
				max-width: 320px;
				&:before {
					display: none;
				}

			}

			@include min-screen($tablet) {
				width: 250px;
			}
		}

		.is-dropdown-right:before {
			right: 5px;
			@include size(21px 32px);
			@include retina-image('#{$path-icon}/arrow-filter-right', 21px 32px);
		}
		.is-dropdown-left:before {
			left: 5px;
			@include size(21px 32px);
			@include retina-image('#{$path-icon}/arrow-filter-left', 21px 32px);

		}

		.control {
			text-align: center;
		}
		.columns {
			.column {
				@include max-screen(767px) {
					&:not(:last-child) {
						padding-bottom: 0;
					}
				}
			}
		}
		
	}
}

.section-product-listing,
.section-product-related {
	.product-listing {
		position: relative;
	}

	.product-item {
		color: $blue;
		display: block;
		margin-top: 30px;
		
		padding: 0 10px;
		
		@include min-screen($tablet) {
			padding: 0 15px;	
		}

		figcaption {
			margin-top: 15px;
			text-align: center;
		}
		img {
			max-height: 250px;
		}
	}
}

.section-product-related {
	.product-item {
		img {
			max-height: 200px;
		}
		figcaption {

		}
	}
}

.section-product-detail {
	.product-image {
		img {
			max-height: 300px;
			@include min-screen($tablet) {
				max-height: 500px;
			}
		}
	}
	.product-detail {
		.title {
			margin-bottom: 15px;
			@include min-screen($tablet) {
				margin-bottom: 30px;	
			}
		}
	}
	
}

.section-product-price {
}

.section-product-action {
	margin-top: 15px;

	.retail {
		@include max-screen($mobile) {
			.dropdown {
				display: block;
				max-width: 290px;
				.button {
					width: 100%;
					min-width: auto;
					max-width: 290px;
				}
			}	
		}
		@include min-screen($tablet) {
			.dropdown {
				.button {
					min-width: 290px;
				}
			}	
		}
	}
}

.section-product-description {
	.content-wrapper {
		position: relative;
		padding: 15px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			@include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);	
		}

		.inner {
			position: relative;
			z-index: 2;
			background: white;
			padding: 10px 15px 15px;
			@include min-screen($tablet) {
				padding: 15px 30px 30px;
			}
		}
	}

	.title-des {
		// margin-bottom: 0 !important;
	}
}