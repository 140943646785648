@include font-face("PSLKandaBold", "../fonts/PSLKandaBold/PSLKandaBold", $file-formats: eot woff ttf svg);
@include font-face("PSLKandaExtra", "../fonts/PSLKandaExtra/PSLKandaExtra", $file-formats: eot woff ttf svg);

@include font-face("PSLFreestyle", "../fonts/PSLFreestyle/PSLFreestyle", $file-formats: eot woff ttf svg);
@include font-face("PSLFreestyleBold", "../fonts/PSLFreestyleBold/PSLFreestyleBold", $file-formats: eot woff ttf svg);

html {
	word-break: break-word;
}

body {
	font-size: $body-size;
}

a {
	&:focus {
		outline: none !important;
	}
}
.title {
	// font-family: $font-body;
	font-weight: normal;
	&:not(:last-child) {
		margin-bottom: 15px;
	}
}
.subtitle {

}


@include max-screen($mobile) {

	.title {
		line-height: 0.8;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	.is-2,
	.is-size-2,
	.is-size-3, {
		line-height: 0.8;
	}

	.is-size-4,
	.is-5,
	.is-size-5 {
		line-height: 0.9;
	}

	.is-48-mobile-32 {
		font-size: 32px;
	}

}
@include min-screen($tablet) {

	.is-2,
	.is-size-2,
	.is-size-3, {
		line-height: 0.8;
	}

	.is-4,
	.is-size-4,
	.is-5,
	.is-size-5 {
		line-height: 0.9;
	}

	.is-48-mobile-32 {
		font-size: 48px;
	}
}


.field.is-grouped>.control:not(:last-child) {
	margin-right: 10px;
}

.swiper-button-prev {
	left: -30px;
	@include retina-image('#{$path-icon}/slide-btn-prev', 21px 35px);
	&.swiper-button-disabled,
	&.swiper-button-disabled:hover {
		@include retina-image('#{$path-icon}/slide-btn-prev', 21px 35px);
	}
	&:hover {
		@include retina-image('#{$path-icon}/slide-btn-prev-active', 21px 35px);
	}
}

.swiper-button-next {
	right: -30px;
	@include retina-image('#{$path-icon}/slide-btn-next', 21px 35px);
	&.swiper-button-disabled,
	&.swiper-button-disabled:hover {
		@include retina-image('#{$path-icon}/slide-btn-next', 21px 35px);
	}
	&:hover {
		@include retina-image('#{$path-icon}/slide-btn-next-active', 21px 35px);
	}
}

.swiper-button-prev,
.swiper-button-next {
	margin-top: -35px;
	
	width: 21px;
	height: 35px;
	background-size: 21px 35px;
	@include transform(translateY(-50%));

	z-index: 20;
	&:hover {
		cursor: pointer;
	}
	&.is-bottom {
		bottom: 33px;
		top: auto;
		@include transform(translateY(0));
		z-index: 20;
	}
}

.swiper-button-prev.is-bottom { left: 0; }
.swiper-button-next.is-bottom { right: 0; }

.swiper-pagination {
	margin: 30px auto;
	height: 30px;
	bottom: auto;
	width: auto;
	position: relative;
	display: table;
	color: #e185cf;
	font-size: 26px;
	line-height: 30px;

	.swiper-pagination-bullet {
		&:first-child { padding-left: 0; }
		&:last-child { padding-right: 0; }
		@include size(20px 15px);
		padding: 0 5px;
		display: table-cell;
		background: none;
		position: relative;
		opacity: 1;
		border-radius: 0;
		background: none;
		vertical-align: middle;
		&:hover {
			&:not(.swiper-pagination-bullet-active) span {
				border: 2px solid darken($pink, 10%);
			}
		}
		span {
			@include size(15px);
			display: block;
			margin: 0 auto;
			border-radius: 50%;
			border: 2px solid $pink;
			background: none;
		}
		&.swiper-pagination-bullet-active span {
			background: $pink;
		}
	}
}

@include max-screen($max-touch) {
	.swiper-button-prev,
	.swiper-button-next {
		bottom: 33px;
		top: auto;
		@include transform(translateY(0));
		z-index: 20;
		width: 15px;
		height: 24px;
		background-size: 15px 24px !important;
	}
	.swiper-button-prev {
		left: 0;
		// background-position: left center;
	}
	.swiper-button-next {
		right: 0;
	}

}

$social-size: 30px;
.social-icon {
	display: block;
	background-repeat: no-repeat;
	@include transition(opacity 0.3s);
	&:hover {
		opacity: 0.7;
	}
	@include size($social-size);
	&.social-fb {
		@include retina-image('#{$path-icon}/social_30/fb', $social-size $social-size);
	}
	&.social-tw {
		@include retina-image('#{$path-icon}/social_30/tw', $social-size $social-size);
	}
	&.social-ig {
		@include retina-image('#{$path-icon}/social_30/ig', $social-size $social-size);
	}
	&.social-youtube {
		@include retina-image('#{$path-icon}/social_30/youtube', $social-size $social-size);
	}

	@include max-screen($max-touch) {
		$social-size: 55px;

		@include size($social-size);
		&.social-fb {
			@include retina-image('#{$path-icon}/social/fb', $social-size $social-size);
		}
		&.social-tw {
			@include retina-image('#{$path-icon}/social/tw', $social-size $social-size);
		}
		&.social-ig {
			@include retina-image('#{$path-icon}/social/ig', $social-size $social-size);
		}
		&.social-youtube {
			@include retina-image('#{$path-icon}/social/youtube', $social-size $social-size);
		}
	}

	&.is-size-55 {
		$social-size: 55px;

		@include size($social-size);
		&.social-fb {
			@include retina-image('#{$path-icon}/social/fb', $social-size $social-size);
		}
		&.social-tw {
			@include retina-image('#{$path-icon}/social/tw', $social-size $social-size);
		}
		&.social-ig {
			@include retina-image('#{$path-icon}/social/ig', $social-size $social-size);
		}
		&.social-youtube {
			@include retina-image('#{$path-icon}/social/youtube', $social-size $social-size);
		}
	}

	&.is-size-70 {
		$social-size: 70px;

		@include size($social-size);
		&.social-fb {
			@include retina-image('#{$path-icon}/social_70/fb', $social-size $social-size);
		}
		&.social-tw {
			@include retina-image('#{$path-icon}/social_70/tw', $social-size $social-size);
		}
		&.social-ig {
			@include retina-image('#{$path-icon}/social_70/ig', $social-size $social-size);
		}
		&.social-youtube {
			@include retina-image('#{$path-icon}/social_70/youtube', $social-size $social-size);
		}
	}
}

.link-readmore {
	color: $pink;
	font-size: $size-5;
	padding-left: 20px;
	display: inline-block;
	vertical-align: baseline;
	position: relative;
	&:hover {
		color: $pink;
		&:after {
			left: 5px;
			@include transition(left 0.2s);
		}
	}
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		@include size(9px 15px);
		@include retina-image('#{$path-icon}/btn-readmore', 9px 15px);
		background-repeat: no-repeat;
		@include transition(left 0.4s);
	}
}

.btn-plus,
.btn-pink-plus {
	color: white;
	font-size: $size-5;
	padding: 0 20px 0 40px;
	display: inline-block;
	vertical-align: baseline;
	position: relative;
	background: $pink;
	border-radius: 25px;
	border: 1px solid $pink;
	@include transition(all 0.3s);
	&.is-active,
	&:hover {
		color: $pink;
		background: white;
		&:after {
			// left: 20px;
			@include transform(translateY(-50%) rotate(90deg));
			@include retina-image('#{$path-icon}/plus-pink', 15px 15px);
		}
	}
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		left: 15px;
		@include transform(translateY(-50%));
		@include size(15px 15px);
		@include retina-image('#{$path-icon}/plus-white', 15px 15px);
		@include transition(all 0.3s);
		background-repeat: no-repeat;
	}
}

.btn-readmore {
	color: $pink;
	font-size: $size-5;
	padding: 0 30px 0 50px;
	display: inline-block;
	vertical-align: baseline;
	position: relative;
	background: white;
	border-radius: 25px;
	border: 1px solid $pink;
	// width: 140px;
	@include transition(all 0.3s);
	&.is-active,
	&:hover {
		color: white;
		background: $pink;
		&:after {
			left: 30px;
			@include retina-image('#{$path-icon}/btn-readmore-white', 9px 15px);
		}
	}
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		left: 25px;
		@include transform(translateY(-50%));
		@include size(9px 15px);
		@include retina-image('#{$path-icon}/btn-readmore', 9px 15px);
		background-repeat: no-repeat;
		@include transition(left 0.2s);
	}
}
.column {
	padding: 15px;
}

.section-follow-us {
	margin: 60px 0;
	@include max-screen(767px) {
		margin: 15px 0 30px;
	}

	.container-follow-us {
		@include retina-image('#{$path-img}/footer/follow-us', 221px 48px);
		padding-top: 50px;
		background-repeat: no-repeat;
		width: 221px;
		margin: 0 auto;

		&.is-two-social {
			padding-top: 40px;
			width: 240px;
			@include retina-image('#{$path-img}/footer/follow-us-2', 240px 80px);
		}
	}
}





.select-custom.select {
	margin: 0 auto;
	height: auto;
	&:after {
		border: 0;
		margin: 0;
		width: 21px;
		height: 13px;
		right: 15px;
		transform: rotate(0deg) translateY(-50%);
		@include retina-image('#{$path-icon}/arrow-select', 21px 13px);
		background-repeat: no-repeat;
		background-position: center center;
	}

	select {
		height: 40px;
		padding: 0 45px 0 15px;
		border-radius: 20px;
		color: white;
		border: 0;
		font-size: 26px;
		min-width: 250px;
		@include max-screen(767px) {
			min-width: 290px;
		}
	}
}

.dropdown.is-custom {

	&.dropdown-shop-online {
		width: 290px;
		@include max-screen(767px) {
			width: 230px;
		}
	}

	.dropdown-trigger {
		width: 100%;
	}

	.button {
		border: 0;
		height: auto;
		padding: 0 45px 0 15px;
		line-height: 40px;
		font-size: 26px;
		position: relative;
		border-radius: 20px;
		position: relative;
		width: 100%;
		@include transition(border-radius 0.4s);


		&:after {
			content: '';
			position: absolute;
			top: 50%;
			border: 0;
			margin: 0;
			width: 21px;
			height: 13px;
			right: 15px;
			transform: rotate(0deg) translateY(-50%);
			@include retina-image('#{$path-icon}/arrow-select', 21px 13px);
			background-repeat: no-repeat;
			background-position: center center;

			transform-origin: top center;

			@include transition(all 0.4s);
		}

	}

	&.is-active,
	&.is-active-dropdown {
		.button {
			border-radius: 20px 20px 0 0;
			&:after {
				transform: rotate(180deg) translateY(-50%);
			}
		}
	}
	&.is-style-blue-curious {
		.button,
		.dropdown-content {
			background-color: $blue-curious;
			color: white;
		}
		.dropdown-content {
		}
	}
	&.is-style-blue-aquamarine {
		.button,
		.dropdown-content {
			background-color: $blue-aquamarine;
			color: white;
		}
		.dropdown-content {
		}
	}

	// ._bg-blue-aquamarine { background-color: $blue-aquamarine !important; }

	.dropdown-menu {
		padding: 0;
		width: 100%;
	}
	.dropdown-content {
		border-radius: 0;
		box-shadow: none;
		padding: 0 15px 10px;
		border-radius: 0 0 20px 20px;
		text-align: left;

		a {
			font-size: 26px;
			line-height: 30px;
			padding: 5px 0;
			color: white;
			border-top: 1px solid white;

			&.dropdown-item.is-active,
			&:hover {
				background: none;
			}
		}
	}

	a.dropdown-item-icon {
		position: relative;
		padding: 10px 0 10px 60px;
		.is-icon {
			position: absolute;
			top: 5px;
			left: 0;
			img { display: block; }
		}
	}

}


.content {
	font-size: 26px;
	line-height: 1;
	img {
		height: auto !important;
		display: inline-block !important;
	}

	strong {
		font-family: $font-bold;
		font-weight: normal !important;
	}

}




.section-header-page {
	.title-page {
		margin-bottom: 10px;
		line-height: 0.7;
	}
}


.btn-category,
.btn-category-review {
	display: block;
	text-align: center;
	width: 140px;
	@include min-screen(375px) {
		min-width: 165px;
	}
	@include min-screen(768px) {
		min-width: 210px;
	}

	.text {
		// margin-top: 10px;
		display: table;
		width: 100%;
		height: 60px;
		border: 1px solid $pink;
		border-radius: 50px;
		> span {
			display: table-cell;
			vertical-align: middle;
			font-size: 26px;
			line-height: 18px;
			padding: 0 5px;
		}
	}

	&:hover,
	&.is-active {
		.text {
			color: white;
			background-color: $pink;
		}
	}
}
.btn-category-review {
	width: 100%;
	min-width: auto;
	@include max-screen($mobile) {
		span { font-size: 22px !important; }
	}
}


.bg-pattern {
	// background-image: url('#{$path-img}/bg-pattern-content.png');
	background-repeat: repeat-x;
	background-size: cover;
	height: 15px;
	width: 100%;

	&.blogger-review {
		background-image: url('#{$path-img}/bg-pattern-blogger-review.png');
	}

	&.consumer-review {
		background-image: url('#{$path-img}/bg-pattern-consumer-review.png');
	}

	&.article {
		background-image: url('#{$path-img}/bg-pattern-article.png');
	}
}

.bg-pattern-activity {
	background-image: url('#{$path-img}/bg-pattern-content-activity.png');
	background-repeat: repeat-x;
	background-size: cover;
	height: 15px;
	width: 100%;
}


.breadcrumb-custom {
	line-height: 20px;
	margin: 0 0 10px;
	a {
		display: inline;
		position: relative;
		padding: 0 5px;
		color: $gray;
		&:hover:not(:last-child) {
			color: $pink;
		}
		
		&:first-child {
			padding: 0 5px 0 0;
		}
		&:last-child {
			padding: 0 0 0 5px;
		}
		&.is-active,
		&.is-active:hover {
			pointer-events: none;
			cursor: default;
		}

	}

	.icon-arrow {
		img {
			@include size(6px 10px);	
			@include transform(translateY(1px));
		}
	}
}


.section-about-logo {
	.is-logo {
		max-width: 350px;
		@include max-screen($mobile) {
			max-width: 80%;
		}
	}
}
.section-about-content {
	margin-bottom: 30px;
	@include min-screen($desktop) {
		margin-bottom: 60px;
	}
}

.shopping-list a {
	display: block;
	width: 137px;
	@include min-screen($tablet) {
		width: 173px;
	}
	@include min-screen($desktop) {
		width: 120px;
	}
}


.section-error-content {
	margin: 15px 0 30px;
	@include max-screen($mobile) {
		.inner { padding: 50px 15px; }
	}
	@include max-screen($max-touch) {
		.inner { padding: 80px 15px; }
	}
	@include min-screen($desktop) {

		.inner { padding: 120px 15px; }
	}

}

.section-search-content {
	.search-result-swiper {
		position: relative;
	}
	.title-result {
		margin: 30px 0;
	}
	.block-result {
		margin: 0 0 30px 0;
		p { margin-bottom: 30px !important; }

		&:last-child {
			margin-bottom: 0;
		}
	}
	@include min-screen($tablet) {
		.title-result {
			margin: 60px 0;
		}
		.block-result {
			margin: 0 0 60px 0;
			h1,
			a {
				margin-bottom: 10px!important;
				display: block;
			}
			p { margin-bottom: 60px !important; }
		}
	}
}



.link-messenger {
	margin-top: -30px;
	padding-right: 10px;
	display: inline-block;
	@include transition(opacity 0.3s);
	&:hover {
		opacity: 0.7;
	}
}

.responsive-youTube{
		margin-bottom: 15px;
		
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.responsive-youTube iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}