.footer-page {
	background-color: #7cd4e5;
	padding: 60px 0px 30px;
	color: $blue;
	@include min-screen(1024px) {
		> .container {
			max-width: 970px;
		}
	}

	@include max-screen(767px) {
		padding: 15px 0px 30px;
	}

	
	a {
		color: $blue;
		&.is-menu {
			font-size: 23px;
			margin-bottom: 15px;
			// font-family: $font-bold;
			white-space: nowrap;
			text-shadow: 0.2px 0.3px 0px #3d697f;
		}
		&:hover {
			color: white;
		}
	}
	.is-submenu {

		a {
			display: block;
			line-height: 22px;
		}
	}

	.container-menu {
		// padding-right: 20px;
	}

	.is-one-third-tablet {
		@include min-screen(1199px) {
			width: auto;
		}
	}

	.columns-menu {
		@include max-screen(767px) {
			margin-bottom: 0;
			.column {
				padding-bottom: 0;
			}
		}
	}
}