.section-hero-review {
	.hero-wrapper {
		// z-index: 1; 
		margin-top: -15px;
	}
}


.wrapper-left {
	@include min-screen($desktop) {
		max-width: 416px;
	}
}

.section-review-slide {
	.review-swiper {
		position: relative;
	}

	.title-gradient {
		margin-bottom: 30px;
	}

	@include max-screen($mobile) {
		&.is-section {
			margin-top: 15px;
		}

		.title-gradient {
			margin-bottom: 15px;
		}
	}
}

.section-review-content {
	.content {
		margin: 60px 0;
		@include max-screen($mobile) {
			margin: 30px 0;
		}
		@include min-screen($desktop) {
			width: 720px;
		}
	}
}


.block-review-by {
	margin: 15px 0;
	.subtitle-review-by {
		position: relative;
		display: inline-block;
		&:after {
			position: absolute;
			content: '';
			top: -12px;
			right: -25px;
			display: block;
			@include size(47px 29px);
			@include retina-image('#{$path-icon}/arrow-review', 47px 29px);
		}
	}

	.review-by {
		margin-top: 5px;
	}
}

.hero-review-swiper,
.review-slider {
	position: relative;
}

.hero-review-swiper {
	@include min-screen($desktop) {
		max-width: 730px;
	}
}

#review-thumbs {
	box-sizing: border-box;
	overflow: hidden;
	margin-top: 10px;
	position: relative;
	.wrapper {
		
	}
	.thumb {
		cursor: pointer;
		padding: 5px;
		display: inline-block;
		float: left;
		position: relative;

		.inner {
			position: relative;
			border: 5px solid white;
		}
		> div {
			position: relative;
			z-index: 3;
		}
		&.is-active,
		&:hover {
			&:before {
				@include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;
		}

		&.is-more {
			.inner {
				&:after {
					position: absolute;
					z-index: 1;
					content: '';
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.5);
				}
				&:hover {
					background: rgba(0,0,0,0.7);
				}
			}
		}

		.over {
			z-index: 5;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
			font-size: 55px;
			z-index: 4;
			text-align: center;
			@include transform(translateY(-50%));
			color: white;
		}

		.btn-player {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
			background: rgba(255,255,255,0.5);
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin: 0 auto;
				display: block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 40px;
				@include size(40px);
				@include transform(translateY(-50%));
				@include retina-image('#{$path-icon}/play', 40px 40px);
			}
			&:hover {
				&:after {
				}
			}
		}
	}
}


.pagination-thumbs {
	position: relative;
	display: table;
	margin: 30px auto;
	.bullet {
		padding:0 5px;
		width: 25px;
		height: 15px;
		display: table-cell;
		position: relative;
		&:last-child {
			// padding-right: 0;
			// width: 15px;
		}
		&:after {
			content: '';
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			position: absolute;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			border: 2px solid #e185cf;
		}

		&.is-active,
		&:hover {
			&:after {
				background-color: $pink;
			}
		}
	}
}


.button-thumbs-prev,
.button-thumbs-next {
	background-repeat: no-repeat;
	width: 15px;
	height: 24px;
	background-size: 15px 24px;
	font-size: 0;
	position: absolute;
	bottom: 25px;
	z-index: 10;
	&:hover {
		cursor: pointer;
	}
}
.button-thumbs-prev {
	left: 0;
	@include retina-image('#{$path-icon}/slide-btn-prev', 15px 24px);
	&.is-disabled,
	&.is-disabled:hover {
		pointer-events: none;
		opacity: 0.35;
		// @include retina-image('#{$path-icon}/slide-btn-prev', 15px 24px);
	}
	&:hover {
		@include retina-image('#{$path-icon}/slide-btn-prev-active', 15px 24px);
	}
}

.button-thumbs-next {
	right: 0;
	@include retina-image('#{$path-icon}/slide-btn-next', 15px 24px);
	&.is-disabled,
	&.is-disabled:hover {
		pointer-events: none;
		opacity: 0.35;
		// @include retina-image('#{$path-icon}/slide-btn-next', 15px 24px);
	}
	&:hover {
		@include retina-image('#{$path-icon}/slide-btn-next-active', 15px 24px);
	}
}

.review-hilight-swiper {
	margin-right: -5px;

	.slide-image {
		padding: 10px;
		margin: -15px 5px 5px 0;
		box-shadow: 2px 3px 4px rgba(10,10,10,.1);
	}

	.slide-header {
		@include max-screen($mobile) {
			margin-right: 5px;
		}
	}
}