.page-home {
    .hero-banner,
    .hero-wrapper {
        position: relative;
    }

    .activity-swiper {
        position: relative;
    }
}

.banner-wrapper {
    position: relative;
    padding: 10px;
    box-shadow: 2px 3px 4px rgba(10, 10, 10, 0.1);
    .swiper-slide {
        position: relative;
    }
    .player-wrapper {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        z-index: 100;
        @include max-screen(1199px) {
            .video-container {
            }
        }
        @include min-screen($desktop) {
            padding: 0;
            // background: red;
            width: 40%;
        }

        &:hover {
            .thumbnail-player {
                &:after {
                    @include retina-image('#{$path-icon}/play-hover', 60px 60px);
                }
            }
        }

        &.is-playing {
            .thumbnail-player {
                //z-index: 0;
                //opacity: 0;
                display: none;
            }
            .video-container {
                opacity: 1;
            }
        }

        .thumbnail-player {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 200;
            width: 100%;

            @include transition(all 0.3s);

            &.is-disabled {
                z-index: 0;
            }

            &:after,
            &:before {
                z-index: 102;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
            }
            &:before {
                background: white;
                opacity: 0.5;
            }
            &:after {
                background-repeat: no-repeat;
                background-position: center;
                @include retina-image('#{$path-icon}/play', 60px 60px);
            }
        }
        .video-container {
            z-index: 111;
            opacity: 0;
            position:relative;
            padding-bottom:56.25%;
            // padding-top:30px;
            height:0;
            overflow:hidden;
        }

        .video-container iframe, .video-container object, .video-container embed {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }

        &.is-full-slide {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            width: 100%;
        }

    }
}

#btn-seeall-activity {
    margin: 10px 0;
    @include min-screen($desktop) {
        position: absolute;
        right: 0;
        bottom: 75px;
        z-index: 2;
    }
}

.section-activity {
    // margin-top: 30px;
}

.section-product {
    // margin-top: 30px;

    .container-product {
        min-width: 345px;
        // min-height: 350px;
        display: block;
        position: relative;
        padding: 11px 11px 11px 11px;
        margin-bottom: 50px;
        @include max-screen(374px) {
            min-width: 290px;
        }
        &:hover {
            .title {
                color: $pink-lady;
            }
            .product-image {
                @include transform(translateY(10px));
            }
            &:before {
                //background-color: $pink-lady;
                opacity: 1;
                //@include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);
            }
        }
        .inner {
            z-index: 10;
            position: relative;
            background: white;
            padding-top: 0;
            height: 350px;
        }
        .title {
            padding-top: 30px;
            margin-bottom: 10px;
            color: $blue;
            @include transition(color 0.5s);
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #f5f5f5;
            z-index: 1;
            @include transition(all 0.5s);
        }
        &:before {
            z-index: 2;
            opacity: 0;
            @include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);
        }
        &.is-highlight {
            &:after {
                // background: red;
                //@include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);
            }
        }

        .product-image {
            @include transition(transform 0.5s);
            @include transform(translateY(0px));
            img {
                margin: 0 auto;
            }
        }

        .bg-gradient {
            height: 5px; width: 150px; margin: 0 auto 15px;
        }
    }

    .category-icon {
        img { 
            display: block;
            margin: 0 auto;
        }
    }
}

.section-product-review {
    
    margin: 30px auto 0;

    .container {
        @include min-screen($desktop) {
            width: 970px !important; 
        }
    }

    .review-swiper {
        max-width: 970px;
        margin: 0 auto;
        position: relative;
        @include screen(375px, 1199px) {
            width: 100%;
            max-width: 455px;
            margin: 0 auto 30px;
        }
        .swiper-container {
            margin-bottom: 15px;
        }

        @include min-screen(1200px) {
            .swiper-button-prev,
            .swiper-button-next {
                margin-top: -75px;
            }
        }
    }

    .container-review {
        position: relative;
        // margin: 15px auto;
        // max-width: 455px;
        @include max-screen(374px) {
            // width: 290px;
        }
        @include screen(375px, 1199px) {
            // width: 100%;
        }
        @include min-screen(1200px) {
            // margin: 15px auto;
            // width: 455px;
        }
    }


}


.container-review,
.container-activity {
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(../image/bg-pattern.png);
        background-repeat: repeat;
        z-index: 1;
    }

    .inner {
        padding: 1px;
        z-index: 10;
        position: relative;
        .content-body {
            text-align: center;
            padding: 20px;
            img {

            }
        }
    }

    .content-header {
        background-color: white;
        text-align: center;
        position: relative;
        // margin-bottom: 30px;
        padding: 10px 0;
        &:after {
            position: absolute;
            content: '';
            bottom: -10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            @include arrow-down(10px, white);
        }
    }

    .content-body {
        color: $blue;
        a.content-link {
            color: $blue;
            &:hover, &:focus, &:visited {
                color: $blue;
            }
        }
        figcaption {
            margin: 20px 0 0;
            font-size: $size-4;
            line-height: 0.9;
            height: 56px;
            overflow: hidden;
        }
        p {
            line-height: 1;
            font-size: $size-5;
            margin-bottom: 20px;
        }
    }

    &.blogger-review,
    &.is-blogger {
        background-color: $pink-cotton-candy;
        .title {
            color: $pink-lady;
        }
    }

    &.consumer-review,
    &.is-consumer {
        background-color: $blue-power;
        .title {
            color: $blue-light;
        }
    }

    &.article,
    &.is-article {
        background-color: $beauty-bush;
        .title {
            color: $coral;
        }
    }

    &.activity,
    &.is-activity {
        background-color: #eac0e8;
        .title {
            color: $coral;
        }
    }

    .bg-gradient {
        height: 5px;
        max-width: 100%;
        width: 308px;
        @include max-screen(374px) {
            width: 250px;
        }
        margin: 20px auto;
    }
}











