
@mixin placeholder($font-size,$font-color,$line-height) {
	&::-moz-placeholder {
		font-size: $font-size;
		color: $font-color;
		line-height: $line-height;
	}
	&:-ms-input-placeholder {
	  	font-size: $font-size;
	  	color: $font-color;
	  	line-height: $line-height;
	}
	&::-webkit-input-placeholder {
	 	font-size: $font-size;
	 	color: $font-color;
	 	line-height: $line-height;
	}
}


@mixin arrow-up($width,$color) {
	width: 0; 
	height: 0; 
	border-left: $width solid transparent;
	border-right: $width solid transparent;
	
	border-bottom: $width solid $color;
}

@mixin arrow-down($width,$color) {
	width: 0; 
	height: 0; 
	border-left: $width solid transparent;
	border-right: $width solid transparent;
	
	border-top: $width solid $color;
}

@mixin arrow-right($width,$color) {
	width: 0; 
	height: 0; 
	border-top: $width solid transparent;
	border-bottom: $width solid transparent;
	
	border-left: $width solid $color;
}

@mixin arrow-left($width,$color) {
	width: 0; 
	height: 0; 
	border-top: $width solid transparent;
	border-bottom: $width solid transparent; 
	
	border-right:$width solid $color; 
}