// font
// .font-head { font-family: $font-head; }
.font-body { font-family: $font-body; }
.font-bold,
._f-bold { font-family: $font-bold; }

.font-base { font-family: serif; }

.font-freestyle {font-family: "PSLFreestyle"; }
.font-freestyle-bold { font-family: "PSLFreestyleBold"; }



// Color
._c-white { color: white; }
._c-black { color: $black; }
._c-pink { color: $pink !important; }
._c-pink-lady { color: $pink-lady !important; }
._c-coral { color: $coral; }
._c-blue { color: $blue !important; }
._c-blue-light { color: $blue-light !important; }

._c-purple { color: $purple; }

._c-blue-curious { color: $blue-curious; }
._c-blue-aquamarine { color: $blue-aquamarine; }

._bg-blue-curious { background-color: $blue-curious !important; }
._bg-blue-aquamarine { background-color: $blue-aquamarine !important; }

.block-shadow {
	position: relative;
	padding: 10px;
	box-shadow: 2px 3px 4px rgba(10, 10, 10, 0.1);
}

.block-table,
.block-column,
.block-cell {
	display: table;
	width: 100%;
	&.is-cell-center {
		> .cell,
		> .column {
			vertical-align: middle;
		}
	}
	&.fixed-layout {
		table-layout: fixed;
	}
	> .cell,
	> .column { 
		padding: 0;
		display: table-cell;
		vertical-align: top;
		&.-v-bot { vertical-align: bottom; }
		&.v-middle,
		&.is-v-middle { vertical-align: middle; }
		&._pr5 {
			padding-right: 5px;
		}
		&._pr10 {
			padding-right: 10px;
		}
	}
	&.no-column-xs {
		@include max-screen(767px) {
			width: 100%;
			> .cell {
				display: block;
				text-align: left;
			}
		}
	}
}

.row.no-gutter {
	margin-left: 0;
	margin-right: 0;
}

.center,
.center-align { text-align: center; }

.right-align { text-align: right; }

.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
	padding-right: 0;
	padding-left: 0;
}

._col-gutter-5 {
	padding: 0 10px 0 15px;
	div[class*=col-] {
		padding: 0 5px 0 0;
	}
}

._col-gutter-10 {
	padding: 0 5px 0 15px;
	div[class*=col-] {
		padding: 0 10px 0 0;
	}
}


.font {
	&-s14 { font-size: 14px; }
	&-s16 { font-size: 16px; line-height: 29px; }
	&-s18 { font-size: 18px; }
	&-s20 { font-size: 20px; }
	&-s30 { font-size: 30px; }
	// &-s23 { font-size: 23px; }
}

.no-margin {
	margin: 0;
	&-top {
		margin-top: 0;
	}
}

.br-responsive {
	@include max-screen(767px) {}
	@include screen(768px, 1079px) {
		
	}
	@include min-screen(1080px) {
		br {
			display: none;
			&.show-lg {
				display: block;
			}
		}
	}
}


.image-container {
	> img {
		display: block;
	}
	&.is-center {
		text-align: center;
		> img {
			margin: 0 auto;
		}
	}
}
.img-center {
	margin: 0 auto;
}

._text-border {
	@include stroke(1,#FFFFFF);
}


.bg-gradient {
	@include linear-gradient(to right, #f4daf3, #ffd3d3);
}

.container {
	padding: 0 15px;
	@include min-screen($desktop) {
		width: 1140px !important;
	}
}

.is-truncate {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.is-gap-15 {
	margin: -7.5px;
	.column {
		padding: 7.5px;
	}
}

.is-no-gap-mobile {
	@include max-screen($mobile) {
		> .column {
			padding-bottom: 0;
			&:last-child {
				padding-bottom: 15px;
			}
		}
	}
}

.is-no-gap-touch {
	@include max-screen($max-touch) {
		> .column {
			padding-bottom: 0;
			&:last-child {
				padding-bottom: 15px;
			}
		}
	}
}

.is-no-gap-bottom-desktop {
	@include min-screen($desktop) {
		> .column {
			padding-bottom: 0;
			// &:last-child {
			// 	padding-bottom: 15px;
			// }
		}
	}
}

.is-section {
	margin-top: 60px;
	@include max-screen(767px) {
		margin-top: 30px;
	}
}

.is-has-space {
	margin: 30px auto;
	@include max-screen(767px) {
		margin: 15px auto;
	}

	&.is-no-space-bottom-touch {
		@include max-screen($max-touch) {
			margin: 30px auto 0;
		}
	}	
}

.block-gradient {
	position: relative;
	padding: 30px;
	&:before,
	&:after {
		position: absolute;
		left: 0;
		content: '';
		@include linear-gradient(to right, #f4daf3, #ffd3d3);
		height: 5px;
		width: 100%;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}

	&.is-bottom {
		&:before {
			display: none;
		}
	}
}





















.title-heading {
	z-index: 2;
	display: inline-block;
	padding-bottom: 15px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	max-width: 100%;

	.title {
		color: white;
		margin: 0;
		padding: 12px 10px;
		text-align: center;
		width: 100%;
		box-sizing: border-box;
	}

	@include max-screen($mobile) {
		width: 100%;
	}
	@include min-screen($tablet) {
		min-width: 350px;
		.title {
			padding: 15px 10px;
		}
	}

	&.is-responsive {
		width: 100% !important;
	}


	&.is-blogger,
	&.blogger-review {
		text-shadow: 2px 2px $pink-lady;
		background-image: url(../image/category-bg/blogger.png);
	}

	&.is-consumer,
	&.consumer-review {
		text-shadow: 2px 2px $blue-light;
		background-image: url(../image/category-bg/consumer.png);
	}

	&.is-article,
	&.article {
		text-shadow: 2px 2px $coral;
		background-image: url(../image/category-bg/article.png);
	}

	&.is-activity {
		text-shadow: 2px 2px #e185cf;
		background-image: url(../image/category-bg/activity.png);
	}

}

.is-content-gradient {
	position: relative;
	padding: 15px;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		@include linear-gradient(to bottom, #fdd3d5, #dce1e8, #b7effd);	
	}

	.inner {
		position: relative;
		z-index: 2;
		background: white;
		padding: 15px;
		@include min-screen($tablet) {
			padding: 30px;
		}
	}
}

#map {
	width: 100%;
	height: 150px;

	@include min-screen($tablet) {
		height: 340px;
	}

	@include min-screen($desktop) {
		height: 520px;
	}
}


.swiper-custom .swiper-wrapper {
	box-sizing: inherit;
}