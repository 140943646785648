
.navbar {
	padding: 25px 15px;
	
	// background: rgba(0,0,0,0.1);
	max-width: 1170px;
	width: 1170px;
	margin: 0 auto;
	@include min-screen(1200px) {
		min-height: 110px;
		position: inherit;
	}
	@include max-screen(1199px) {
		padding: 15px 15px;
		max-width: 100%;
	}

	.navbar-menu {
		box-shadow: none;
		padding: 0;
	}

	a {
		color: $blue;
	}
}

.navbar-brand {
	.navbar-item {
		padding-left: 0;
		padding-right: 15px;
		&:hover {
			background: none;
			color: none;
		}
	}
	@include max-screen(1199px) {	
		.logo {
			margin: 0 auto;
			// width: 100%;
			text-align: center;
			padding: 0;
		}
	}
}
.navbar-start {
	&>.navbar-item {
		overflow: hidden;
		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			height: 5px;
			width: 0;
			opacity: 0;
			@include linear-gradient(to right, #f4daf3, #ffd3d3);
		}
		&.is-active,
		&:hover {
			background: none;
			color: $pink;
			// @include transition(color 0.2s);
			&:before {
				// @include transition(all 0.2s);
				width: 100%;
				opacity: 1;
			}
			&>a.navbar-link {
				background: none;
				overflow: hidden;
				position: relative;
				color: $pink;
				// @include transition(color 0.3s);
				&:before {
					// @include transition(all 0.3s);
					width: 100%;
					opacity: 1;
				}
				
			}


		}
		&.has-dropdown {
			&:before {
				display: none;
			}
		}

		&>.navbar-link {
			color: $blue;
			overflow: hidden;
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				height: 5px;
				width: 0;
				opacity: 0;
				@include linear-gradient(to right, #f4daf3, #ffd3d3);
			}
		}
	}

}
.navbar-end {
	.navbar-item:last-child {
		padding-right: 0;
	}

	@include max-screen(1199px) {
		padding-bottom: 15px;
		.navbar-item {
			padding: 0 10px !important;
		}
		.field-search {
			margin: 15px 0;
		}
		
		.field-social {
			margin: 30px auto;
		}
	}

	
}
.navbar-item {
	font-size: $size-5;
	padding: 5px 10px;
	// position: relative;
	img {
		max-height: none;
		display: block;
		margin: 0 auto;
	}

	&:hover {

	}
}
.navbar-link {
	padding: 5px 10px;
	&:after  {
		display: none;
	}
}

.navbar-item,
.navbar-link {
}


.navbar-dropdown {
	border-top: none;
	padding: 0;
	&.is-active.navbar-dropdown-fullwidth {

	}
	a.navbar-item {
		padding: 0;
		&.is-active {
			color: $pink;
		}
	}
	.columns {
		margin: 0 auto;
	}
}
.field-search {
	min-width: 214px;
	background-color: #7cd4e5;
	border-radius: 20px;
	margin-right: 10px;
	overflow: hidden;
	input { 
		background: none;
		box-shadow: none;
		border: none;
		height: 30px;
		padding: 0px 5px 0px 10px !important;
		line-height: 30px;
		color: white !important;
		// font-size: $size-5;
		font-size: 22px;

		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color:    white;
			// font-size: 20px;
			// vertical-align: top;
			// font-size: $size-6;
			// line-height: px;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color:    white;
			opacity:  1;
			// font-size: $size-6;
			// line-height: 30px;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color:    white;
			opacity:  1;
			// font-size: $size-6;
			// line-height: 30px;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color:    white;
			// font-size: $size-6;
			// line-height: 30px;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
			color:    white;
			// font-size: $size-6;
			// line-height: 30px;
		}

	}
	button {
		display: block;
		width: 30px;
		height: 30px;
		padding: 0;
		margin: 0 !important;
		border: none;
		background: none;
		.icon {
			height: 30px !important;
			color: white !important;
			width: 30px !important;	
		}
	}

}
.navbar-burger {
	position: absolute;
	right: 15px;
	z-index: 10;
	&:hover {
		background: none;
		span {
			background: $pink;
		}
	}
	span {
		width: 35px;
		height: 6px;
		border: 1px solid $pink;
		background: white;
		border-radius: 10px;
		margin: 0 auto;
		right: 0;
		left: auto;
		&:nth-child(1) {
			margin-top: -9px;
		}

		&:nth-child(2) {
			margin-top: 0px;
		}
		&:nth-child(3) {
			margin-top: 9px;
		}
	}
	&.is-active {
		span {
			// background: white;
			background: $pink;
			&:hover {
				background: $pink;	
			}
			&:nth-child(1),
			&:nth-child(3) {
				margin-top: 0;
				margin-left: 0;
				transform-origin: center center;
			}
		}
	}
}


@include max-screen(1199px) {
	.navbar-menu {
		position: absolute;
		top: 150px;
		left: 0;
		right: 0;
		z-index: 999;
		background-color: white;
	}
	
	.navbar-menu a.navbar-item,
	.navbar-menu a.navbar-link {
		border-bottom: 1px solid $pink;
		// padding: 5px 15px;
		&:before {
			display: none;
		}
	}

	.navbar-start {
		padding: 0 15px;
	}

	.navbar-start .navbar-item.has-dropdown {
		border-bottom: none;
		&:hover {
			.navbar-link {
				// color: white;
				// background: $pink;
				&:before {
					display: none;
				}
			}
		}

		&.is-expanded {

			&.is-active {
				
				.navbar-link {
					// color: white;
					// background: $pink;
					&:after {
						// background-image: url();
						// @include retina-image('#{$path-icon}/navbar-arrow-up', 25px 15px);
					}
				}
			}
		}

		>.navbar-link {
			background: none;
			position: relative;
			&:after {
				display: none;

				// position: absolute;
				// display: block;
				// content: '';
				// top: 50%;
				// right: 10px;
				// @include size(25px 15px);
				// @include transform(translateY(-50%));
				// @include retina-image('#{$path-icon}/navbar-arrow-down', 25px 15px);
				// background-repeat: no-repeat;
				// // background-size: contain;
				// background-position: right center;
			}
		}

		.navbar-dropdown { 
			// display: none;
			.navbar-item {
				padding: 10px 10px 10px 15px;
				border: none;
				line-height: 1;
				&.is-active,
				&:hover {
					background: none;
					color: $pink;
				}
			}
		}
		.navbar-item {

		}
	}
}

@include min-screen(1200px) {
	.navbar-item.has-dropdown {
		position: initial !important;
		.navbar-dropdown {
			border-radius: 0;
			position: absolute;
			z-index: 100;
			top: 85px;
			left: 0;
			padding: 15px 0 0;
			opacity: 0;
			.columns {
				// margin: 0 auto !important;
				// padding-top: 15px;
				// width: 1140px;
			}
		}

		&.is-active {
			.navbar-dropdown {
				display: none;
			}
		}

		&:hover,
		&.is-active:hover {
			overflow: visible;
			.navbar-dropdown {
				display: block;
				opacity: 1;
			}
		}

		

		.navbar-item {
			padding: 20px 15px;
			&:hover {
				color: $pink;
				background: none;
			}
		}
	}
}