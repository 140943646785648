// Font-size
$size-1: 100px;
$size-2: 55px;
$size-3: 40px;
$size-4: 32px;
$size-5: 26px;
$size-6: 18px;
$size-7: 16px;

$font-body: 'PSLKandaBold', sans-serif;
$font-bold: 'PSLKandaExtra', sans-serif;
$family-sans-serif: $font-body;
$body-size: $size-6;

$gap: 30px;
$mobile: 767px;
$tablet: 768px;
$max-touch: 1199px;
$desktop: 1200px;
$widescreen: $desktop;
$fullhd: $widescreen;


// section
$section-padding: 3rem 1.5rem;
$section-padding-medium: 9rem 1.5rem !default
$section-padding-large: 18rem 1.5rem !default


$pink:	#e185cf;
$color-pink: $pink;
$pink-lady: #f7769e;
$color-pink-lady: $pink-lady;

$purple: #af79b5;

$blue: #3d697f;
$blue-light: #29a3b9;
$blue-curious: #5d9fc1;
$blue-aquamarine: #7cd4e5;


// blogger 
$pink-cotton-candy: #fcbbe1;

// consumer
$blue-power: #AEE2EB;

// article
$coral: #EA8484;
$beauty-bush: #F5BCBC;

// Colors
$black:        	#231f20;
$gray:         	#999;
$gray-light:   	#ccc;
$white:        	#fff;
$green: 		#00ab4e;

// Main Color

// $pink-text: #c52677;
// $black:    	#555555;

// Fonts

// $font-head: 'PSLKandaExtra', sans-serif;

$path-img: '../image';
$path-icon: '../image/_icon';


/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
   @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

